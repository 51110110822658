/* eslint-disable */
/**
 * Trust All Scripts
 *
 * This is a dirty little script for iterating over script tags
 * of your Ghost posts and adding them to the document head.
 *
 * This works for any script that then injects content into the page
 * via ids/classnames etc.
 *
 */
// const { datadogRum } = require('@datadog/browser-rum')
const $ = require('jquery')
const { includes } = require('lodash')
const { removeHellobarUrl } = require('./static/constants')
const axios = require('axios')
// gatsby-browser.js
const { IsMobileProvider } = require('./src/context/isMobileContextApi')

//define hihlight.js
const hljs = require('highlight.js')

const hellobarUrls = require(`/static/constants`).removeHellobarUrl
const url = window.location.pathname

var customScripts = function () {
  $(document).ready(function () {
    window.addEventListener('load', videoScroll)
    window.addEventListener('scroll', videoScroll)

    function videoScroll() {
      if (document.querySelectorAll('video').length > 0) {
        var windowHeight = window.innerHeight,
          videoEl = document.querySelectorAll('video')

        for (var i = 0; i < videoEl.length; i++) {
          var thisVideoEl = videoEl[i],
            videoHeight = thisVideoEl.clientHeight,
            videoClientRect = thisVideoEl.getBoundingClientRect().top
          if (videoClientRect <= windowHeight - videoHeight * 0.5 && videoClientRect >= 0 - videoHeight * 0.5) {
            thisVideoEl.play()
          } else {
            thisVideoEl.pause()
          }
        }
      }
    }
    var isiDevice = /ipad|iphone|ipod/i.test(navigator.userAgent.toLowerCase())
    if (isiDevice == true) {
      $('video').hide()
      $('.mob_featuresImg').show()
    } else {
      $('video').show()
      $('.mob_featuresImg').hide()
    }
    function addCommas(comma) {
      var parts = comma.toString().split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return parts.join('.')
    }
    var counterNumber = 0
    // $(window).scroll(function() {
    //   var oTop = $('.navbar').offset().top - window.innerHeight;
    //   if (counterNumber == 0 && $(window).scrollTop() > oTop) {
    //     $('.counter-value span').each(function() {
    //       var $this = $(this),
    //         countTo = $this.attr('data-count');
    //       $({
    //         countNum: $this.text()
    //       }).animate({
    //           countNum: countTo
    //         },

    //         {
    //           duration: 1000,
    //           step: function() {
    //             $this.text(Math.floor(this.countNum));
    //           },
    //           complete: function() {
    //             $this.text(addCommas(this.countNum));
    //           }

    //         });
    //     });
    //     counterNumber = 1;
    //   }

    // });

    // $('.mobile_listing').on('click', '.mobile_listing', function () {
    //   $(this).find('.dropdownmenu').toggleClass('mobileMenuD')
    // })
    $('.mobileheader_menu li a').click(function () {
      $('.mobile_navbar .navbar-collapse').toggleClass('show')
    })
    $('.listingMenu > a').click(function (event) {
      event.preventDefault()
    })
    $('.box_item .plusText').click(function () {
      $('.box_item').find('.plusDisplayText').removeClass('activeBox')
      $('.box_item').find('.plusText').addClass('activeBox')
      $(this).parent('.box_item').children('.plusDisplayText').toggleClass('activeBox')
      $(this).parent('.box_item').children('.plusText').toggleClass('activeBox')
    })
    $('.scrollToDownload').click(function () {
      var href = $(this).attr('href')
      $('html, body').animate({ scrollTop: $(href).offset().top - 130 }, 500)
    })
    $('.scrollto').click(function () {
      var href = $(this).attr('href')
      $('html, body').animate({ scrollTop: $(href).offset().top - 250 }, 1000)
    })
    $('.section-collapsible-title').click(function () {
      $(this).toggleClass('down-arrow')
    })
    $('.caseFilter').click(function () {
      $('.caseFilter .useCasesBoxes').removeClass('active')
      $('#useCases .usecaseDesc').removeClass('active')
      $('#useCase .col-lg-3').removeClass('active')
      $('#useCasez .col-lg-3').removeClass('active')
      $('#useCasesz .usecaseDesc').removeClass('active')
      var getClassName = $(this).children('.useCasesBoxes').attr('attr')
      $(this).children('.useCasesBoxes').addClass('active')
      $('#useCases')
        .children('#' + getClassName)
        .addClass('active')
      $('#useCasesz')
        .children('#' + getClassName)
        .addClass('active')
      $(this).addClass('active')
      $('html, body').animate({ scrollTop: $('.caseFilter.active').offset().top - 210 }, 1000)
    })

    $('.header_stick').length &&
      $(window).scroll(function () {
        $(window).scrollTop()
          ? $('.header_stick').addClass('navbar_fixed')
          : $('.header_stick').removeClass('navbar_fixed')
      }),
      $('.bar_menu').length &&
        ($('.bar_menu').on('click', function () {
          $('#menu').toggleClass('show-menu')
        }),
        $('.close_icon').on('click', function () {
          $('#menu').removeClass('show-menu')
        })),
      $('.offcanfas_menu .dropdown').on('show.bs.dropdown', function (n) {
        $(this).find('.dropdown-menu').first().stop(!0, !0).slideDown(400)
      }),
      $('.offcanfas_menu .dropdown').on('hide.bs.dropdown', function (n) {
        $(this).find('.dropdown-menu').first().stop(!0, !0).slideUp(500)
      }),
      $('.box_item').hover(function () {
        $('.mid-activity').find('.active-img').removeClass('active-img'),
          $('.features').find('.active-sec').removeClass('active-sec'),
          $('#' + $(this).attr('data-img') + ' img').addClass('active-img'),
          $(this).addClass('active-sec')
      })

    $(function (e) {
      $('nav .navbar-nav a').each(function () {
        this.href === window.location.href && $(this).addClass('active')
      })
    })

    $('.box_item').hover(function () {
      $('.mid-activity').find('.active-img').removeClass('active-img'),
        $('.features').find('.active-sec').removeClass('active-sec'),
        $('#' + $(this).attr('data-img') + ' img').addClass('active-img'),
        $(this).addClass('active-sec')
    })

    $('.tabValues li').on('click', function () {
      var currentItem = '.' + $(this).attr('attr')
      $('.tabValues').find('.active').removeClass('active')
      $(this).addClass('active')
      $('.pricingpage').find('.showItem').removeClass('showItem').addClass('hideItem')
      $(currentItem).removeClass('hideItem').addClass('showItem')
    })

    /*tabs*/
    $('.tabValues li').on('click', function () {
      var currentItem = '.' + $(this).attr('attr')
      $('.tabValues').find('.active').removeClass('active')
      $(this).addClass('active')
      $('.pricingpage').find('.showItem').removeClass('showItem').addClass('hideItem')
      $(currentItem).removeClass('hideItem').addClass('showItem')
    })

    var hellobarStatus = sessionStorage.getItem(`helloBar`)
    // if (hellobarStatus == "false") {
    //     $("#maincont").removeClass("mainpid");
    // } else if (!removeHellobarUrl.includes(url)) {
    //     $("#maincont").addClass("mainpid");
    // }
  })
}

// Adding scroll event listener
// document.addEventListener('scroll', horizontalScroll);

// let sticky = document.querySelector('.stickys');
// let stickyParent = document.querySelector('.sticky-parent');

// let scrollWidth = sticky.scrollWidth;
// let verticalScrollHeight = stickyParent.getBoundingClientRect().height-sticky.getBoundingClientRect().height;

// function horizontalScroll(){

//     let stickyPosition = sticky.getBoundingClientRect().top;
//     if(stickyPosition > 1){
//         return;
//     }else{
//         let scrolled = stickyParent.getBoundingClientRect().top;
//         sticky.scrollLeft =(scrollWidth/verticalScrollHeight)*(-scrolled)*0.85;

//     }
// }

var trustAllScripts1 = function () {
  var scriptNodes = document.querySelectorAll('.load-external-scripts script')

  for (var i = 0; i < scriptNodes.length; i += 1) {
    var node = scriptNodes[i]
    var s = document.createElement('script')
    s.type = node.type || 'text/javascript'

    if (node.attributes.src) {
      s.src = node.attributes.src.value
    } else {
      s.innerHTML = node.innerHTML
    }

    document.getElementsByTagNam$('head')[0].appendChild(s)
  }
  const urls = ['https://www.vantagefit.io/']
  // const ldjson = [
  //   `
  //     {
  //       "@context": "http://schema.org",
  //       "@graph": [
  //           {
  //       "@type": "WebSite",
  //       "name": "Vantage Fit",
  //       "url": "https://www.vantagefit.io/",
  //       "inLanguage": "en-US"
  //       },
  //       {
  //       "@id": "https://www.vantagefit.io/#organization",
  //       "@type": "Organization",
  //       "name": "Vantage Fit",
  //       "parentOrganization": "Vantage Circle",
  //       "description": "Vantage Fit is an AI Powered Corporate wellness solution to enhance employee wellbeing and productivity.",
  //       "url": "https://www.vantagefit.io",
  //       "foundingDate": "2017",
  //       "logo": {
  //       "@type": "ImageObject",
  //       "url": "https://res.cloudinary.com/vantagecircle/image/upload/w_220/VantageFit/website/01-vantagefit-logo-2_compress.png",
  //       "width": 300,
  //       "height": 44
  //       },
  //       "address":
  //      [
  //        {
  //          "@type": "PostalAddress",
  //          "streetAddress": "C1-1151/Vasant Kunj",
  //          "addressLocality": "New Delhi",
  //          "postalCode": "110070",
  //          "addressCountry": "IN"
  //         },
  //         {
  //          "@type": "PostalAddress",
  //          "streetAddress": "4512 Legacy Drive, Suite100, Plano",
  //          "addressLocality": "Texas",
  //             "postalCode": "75024",
  //             "addressCountry": "US"
  //           },
  //           {
  //             "@type": "PostalAddress",
  //             "streetAddress": "Suite 1A, Level 2, 802-808 Pacific Highway, Gordon,",
  //             "addressLocality": "New South Wales",
  //             "postalCode": "2072",
  //             "addressCountry": "AU"
  //           },
  //           {
  //             "@type": "PostalAddress",
  //             "streetAddress": "Suite 1224, 211 Aspen Stone Blvd,",
  //             "addressLocality": "Calgary",
  //             "postalCode": "T3H0K1",
  //             "addressCountry": "CA"
  //           },
  //           {
  //             "@type": "PostalAddress",
  //             "streetAddress": "Startbaan 10 G, Amstelveen,",
  //             "addressLocality": "Netherlands",
  //             "postalCode": "1185 XR",
  //             "addressCountry": "NL"
  //           }
  //         ],
  //           "contactPoint" : [
  //             {
  //               "@type": "ContactPoint",
  //               "contactType": "customer support",
  //               "areaServed" : ["AF","NA","OC","AS","EU","SA"],
  //               "availableLanguage" : ["English"],
  //               "email": "support@vantagecircle.com"
  //             }
  //           ],
  //           "sameAs": [
  //             "https://twitter.com/FitVantage/",
  //             "https://linkedin.com/showcase/vantagefit",
  //             "https://www.facebook.com/Vantagefit-114318070217339/",
  //             "https://www.youtube.com/channel/UCA_X-JntyyNU6rjeRQWHE2w/"
  //           ]
  //         }
  //       ]
  //     }`
  // ]
  // const script = document.createElement(`script`)
  // script.type = "application/ld+json"
  // const json = ldjson
  // document.getElementsByTagName(`head`)[0].appendChild(script).append(json)
}

// trustAllScripts1();
// customScripts();
// circularProgressScripts();

const jQuery = require('jquery')

var tabsScroll = function () {
  $.fn.horizontalTabs = function () {
    // Variable creation
    var $elem = $(this),
      widthOfReducedList = $elem.find('.nav-tabs-horizontal').width(),
      widthOfList = 0,
      currentPos = 0,
      adjustScroll = function () {
        widthOfList = 0
        $elem.find('.nav-tabs-horizontal li').each(function (index, item) {
          widthOfList += $(item).width()
        })

        widthAvailale = $elem.width()

        if (widthOfList > widthAvailale) {
          $elem.find('.scroller').show()
          updateArrowStyle(currentPos)
          widthOfReducedList = $elem.find('.nav-tabs-horizontal').width()
        } else {
          $elem.find('.scroller').hide()
        }
      },
      scrollLeft = function () {
        $elem.find('.nav-tabs-horizontal').animate(
          {
            scrollLeft: currentPos - widthOfReducedList,
          },
          500
        )

        if (currentPos - widthOfReducedList > 0) {
          currentPos -= widthOfReducedList
        } else {
          currentPos = 0
        }
      },
      scrollRight = function () {
        $elem.find('.nav-tabs-horizontal').animate(
          {
            scrollLeft: currentPos + widthOfReducedList,
          },
          500
        )

        if (currentPos + widthOfReducedList < widthOfList - widthOfReducedList) {
          currentPos += widthOfReducedList
        } else {
          currentPos = widthOfList - widthOfReducedList
        }
      },
      manualScroll = function () {
        currentPos = $elem.find('.nav-tabs-horizontal').scrollLeft()

        updateArrowStyle(currentPos)
      },
      updateArrowStyle = function (position) {
        if (position >= widthOfList - widthOfReducedList) {
          $elem.find('.arrow-right').addClass('disabled')
        } else {
          $elem.find('.arrow-right').removeClass('disabled')
        }

        if (position <= 0) {
          $elem.find('.arrow-left').addClass('disabled')
        } else {
          $elem.find('.arrow-left').removeClass('disabled')
        }
      }

    // Event binding
    $(window).resize(function () {
      adjustScroll()
    })

    $elem.find('.arrow-left').on('click.horizontalTabs', function () {
      scrollLeft()
    })

    $elem.find('.arrow-right').on('click.horizontalTabs', function () {
      scrollRight()
    })

    $elem.find('.nav-tabs-horizontal').scroll(function () {
      manualScroll()
    })

    // Initial Call
    adjustScroll()

    return this
  }

  $('#js_image_selection').horizontalTabs()
}

var customjquery = function () {
  jQuery(document).ready(function ($) {
    $('.contentpost img').each(function () {
      $(this).attr('width', '500')
      $(this).attr('height', '400')
      $(this).attr('loading', 'lazy')
      $(this).attr('class', 'lozad')
    })
    $('.contentpost iframe').each(function () {
      $(this).attr('loading', 'lazy')
      $(this).attr('class', 'lozad')
    })

    $('amp-img').removeAttr('loading')
    // $("img").addClass("lozad");
    // $(".load-external-scripts img").removeAttr("loading");
    // $("amp-img").addClass("lozad");

    var popupRequestdemo = localStorage.getItem(`showMsg`)
    if (popupRequestdemo == 'false') {
      $('#footerb').removeClass(`popupspc`)
    }
    $('.content_c ol a').click(function () {
      var href = $(this).attr('href')
      $('html, body').animate({ scrollTop: $(href).offset().top - 130 }, 500)
    })
    $('.all').addClass('showTags')
    var IDs = []
    $('.categories_filtertags > li').on('click', function () {
      $('.contentpost').find('.all').removeClass('showTags').addClass('hideTag')
      $('#all').removeClass('active')
      $(this).toggleClass('active')
      var nameId = $('.categories_filtertags')
        .find('.active')
        .map(function () {
          return this.id
        })
        .get()
      for (var i = 0; i < nameId.length; i++) {
        $('.contentpost')
          .find('.' + nameId[i])
          .addClass('showTags')
      }
      if (nameId == '') {
        $('.all').addClass('showTags')
        $('#all').addClass('active')
      }
    })
    $('.mobile_listing').click(function () {
      $(this).find('.dropdownmenu').toggleClass('mobileMenuD')
    })
    $('.listingMenu > a').click(function (event) {
      event.preventDefault()
    })

    $('.header_stick').length &&
      $(window).scroll(function () {
        $(window).scrollTop()
          ? $('.header_stick').addClass('navbar_fixed')
          : $('.header_stick').removeClass('navbar_fixed')
      }),
      $('.bar_menu').length &&
        ($('.bar_menu').on('click', function () {
          $('#menu').toggleClass('show-menu')
          $('#navbarSupportedContent').toggleClass('show-menu')
        }),
        $('.close_icon').on('click', function () {
          $('#menu').removeClass('show-menu')
        })),
      $('.offcanfas_menu .dropdown').on('show.bs.dropdown', function (n) {
        $(this).find('.dropdown-menu').first().stop(!0, !0).slideDown(400)
      }),
      $('.offcanfas_menu .dropdown').on('hide.bs.dropdown', function (n) {
        $(this).find('.dropdown-menu').first().stop(!0, !0).slideUp(500)
      }),
      $('.box_item').hover(function () {
        $('.mid-activity').find('.active-img').removeClass('active-img'),
          $('.features').find('.active-sec').removeClass('active-sec'),
          $('#' + $(this).attr('data-img') + ' img').addClass('active-img'),
          $(this).addClass('active-sec')
      })

    $(function (e) {
      $('nav .navbar-nav a').each(function () {
        this.href === window.location.href && $(this).addClass('active')
      })
    })

    $('#searchcall').on('click', function (event) {
      $('#search').css('display', 'block')
      $('#search').addClass('show')
      $('#modalback').addClass('modal-backdrop')
      $('#modalback').addClass('fade')
      $('#modalback').addClass('show')
      $('#gatsbyBody').addClass('showClick')
    })

    $('#search_close').on('click', function (event) {
      $('#search').css('display', 'none')
      $('#search').removeClass('show')
      $('#modalback').removeClass('modal-backdrop')
      $('#modalback').removeClass('fade')
      $('#modalback').removeClass('show')
      $('#gatsbyBody').removeClass('showClick')
    })

    $(document).on('click', function (e) {
      $('[data-toggle="popover"],[data-original-title]').each(function () {
        if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
          // (($(this).popover('hide').data('bs.popover')||{}).inState||{}).click = false
        }
      })
    })

    var didScroll,
      lastScrollTop = 0,
      delta = 5

    // Execute on load
    $(window).on('load', function (event) {
      var currentPage = 1
      var pathname = window.location.pathname
      var $result = $('.post')
      var step = 0

      // remove hash params from pathname
      pathname = pathname.replace(/#(.*)$/g, '').replace('///g', '/')

      if ($('body').hasClass('paged')) {
        currentPage = parseInt(pathname.replace(/[^0-9]/gi, ''))
      }
    })

    $('#search').on('shown.bs.modal', function () {
      $('#search-field').focus()
    })

    // Initialize Highlight.js

    $('pre code, pre').each(function (i, block) {
      hljs.highlightBlock(block)
    })

    $(window).on('scroll', function (event) {
      if ($(this).scrollTop() > 0) {
        $('body').addClass('scroll')
      } else {
        $('body').removeClass('scroll')
      }
    })

    // Check if element is into view when scrolling
    function isScrolledIntoView(elem) {
      var docViewTop = $(window).scrollTop()
      var docViewBottom = docViewTop + $(window).height()

      var elemTop = $(elem).offset().top
      var elemBottom = elemTop + $(elem).height()

      return elemBottom <= docViewBottom && elemTop >= docViewTop
    }

    if ($('.error-title').length) {
      $('body').addClass('error')
    }
  })
}

var trustAllScripts = function () {
  var scriptNodes = document.querySelectorAll('.load-external-scripts script')

  for (var i = 0; i < scriptNodes.length; i += 1) {
    var node = scriptNodes[i]
    var s = document.createElement('script')
    s.type = node.type || 'text/javascript'

    if (node.attributes.src) {
      s.src = node.attributes.src.value
    } else {
      s.innerHTML = node.innerHTML
    }

    document.getElementsByTagName('head')[0].appendChild(s)
  }
}

/*exit popup*/
$(document).on('mouseleave', function (e) {
  if (window.innerWidth > 768) {
    leaveFromTop(e)
  }
})
var showExitPopup
$('#ouibounce-modal .modal').hide()
function leaveFromTop(e) {
  if (e.clientY < 0) {
    const showExitPopup = sessionStorage.getItem('showExitPopup')
    if (showExitPopup !== 'false') {
      $('#ouibounce-modal').show()
      $('#ouibounce-modal .modal').show()
    }
    $('#ouibounce-modal .close .cross').click(function () {
      $('#ouibounce-modal .modal').hide()
      sessionStorage.setItem('showExitPopup', 'false')
      $('.underlay').hide()
      $('#ouibounce-modal').hide()
    })
    if (showExitPopup == 'false') {
      $('#ouibounce-modal .modal').hide()
      $('.underlay').hide()
      $('#ouibounce-modal').hide()
    }
  }
}

exports.onRouteUpdate = function () {
  trustAllScripts1()
  customScripts()
  trustAllScripts()
  customjquery()
  tabsScroll()
}

/* wista custom */
const playWista = () => {
  $('.wistia_embed').css('display', 'block')
  // const s =
  // document.getElementsByClassName(`wistia_embed`);
}

if (document.URL.indexOf('/amp/') <= -1) {
  window.onload = function () {
    setTimeout(playWista, 4000)
    //console.log(playWista)
  }
}
/*wista custom ends*/

//add  revenueHero script
let addRevenueHeroScriptPromise = new Promise(function (resolve, reject) {
  var script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = 'https://app.revenuehero.io/scheduler.min.js'

  var head = document.getElementsByTagName('head')[0]
  head.appendChild(script)
  script.onload = function () {
    resolve()
  }
  script.onerror = function () {
    reject('rejected')
  }
})

//revenue hero code
// function executeRevenueHeroScript() {
//   ;(function () {
//     var targetFormIds = [
//       `c169c509-7422-4098-bdd2-085a67d82092`
//     ]
//     if (typeof window === "undefined") return
//     if (window.addEventListener) {
//       window.addEventListener("message", handleFormSubmitEvent, false)
//     } else if (window.attachEvent) {
//       window.attachEvent("onmessage", handleFormSubmitEvent)
//     }
//     function handleFormSubmitEvent(event) {
//       if (
//         event.data.type === "hsFormCallback" &&
//         event.data.eventName === "onFormSubmit"
//       ) {
//         var submittedData = event.data.data

//         // Extract the form ID from the hs_context field value
//         var hsContextField = submittedData.find(function (field) {
//           return field.name === "hs_context"
//         })

//         if (hsContextField) {
//           var hsContextValue = JSON.parse(hsContextField.value)
//           var formId = hsContextValue.originalEmbedContext.formId

//           // Check if the submitted form's ID is in the targetFormIds array
//           if (targetFormIds.includes(formId)) {
//             var formValues = {}
//             submittedData.forEach(function (field) {
//               formValues[field.name] = field.value
//             })
//             //replace routerID with the router ID you've setup
//             const RevenueHero = window.RevenueHero
//             const hero = new RevenueHero({ routerId: `132` })
//             hero.submit(formValues).then(sessionData => {
//               hero.dialog.open(sessionData)
//             })
//           }
//         }
//       }
//     }
//   })()
// }

exports.onClientEntry = () => {
  //cookie consent
//   checkCurrCountry().then((country) => {
//     let allowCookie = false
//     let showConsentBar = true
//     let storedState = JSON.parse(localStorage.getItem('cookieConsentConfig'))
//     if (storedState == null) {
//       setInitialLocalStorageStates(country, allowCookie, showConsentBar)
//     }
//     let gdprSessionStorageState = window.sessionStorage.getItem('allowGtm')

//     if (gdprSessionStorageState == 'true') {
//       setGtm()
//     }

//     createCustomEventToCheckLocalStorageChanges()
//     window.addEventListener(
//       'storage',
//       () => {
//         let gdprSessionStorageState = window.sessionStorage.getItem('allowGtm')
//         if (gdprSessionStorageState == true) {
//           setGtm()
//         } else {
//           return
//         }
//       },
//       false
//     )
//   })

  //revenue hero code
  addRevenueHeroScriptPromise.then(executeRevenueHeroScript()).catch((error) => console.log(error))
  // if (typeof window !== "undefined") {
  //     executeRevenueHeroScript();
  // }

  // REMOVING SOME TAGS FROM PARAMS
  const params = new URLSearchParams(window.location.search)
  if (
    params.has('__hstc') ||
    params.has('__hssc') ||
    params.has('__hsfp') ||
    params.has('ref=') ||
    params.has('utm_')
  ) {
    const meta = document.createElement('meta')
    meta.name = 'robots'
    meta.content = 'noindex, nofollow'
    document.head.appendChild(meta)
  }
  // // Initialize Datadog RUM
  // if (typeof window !== 'undefined') {
  //   datadogRum.init({
  //     applicationId: '63ac863a-9913-4c87-889c-c80e82737392',
  //     clientToken: 'pub71eb8f8bfbd534889ba31d570b82b16b',
  //     site: 'us5.datadoghq.com',
  //     service: 'vantagefit',
  //     env: 'prod',
  //     sessionSampleRate: 100,
  //     sessionReplaySampleRate: 20,
  //     trackUserInteractions: true,
  //     trackResources: true,
  //     trackLongTasks: true,
  //     defaultPrivacyLevel: 'mask-user-input',
  //   })
  // }
}

//revenue hero code
function executeRevenueHeroScript() {
  var targetFormIds = [
    `517ebc0a-e3b2-48e7-994d-19507b711e00`,
    `c169c509-7422-4098-bdd2-085a67d82092`,
    `e23ab1ed-02c7-42c1-90bc-ecd78ae786c3`,
    `3ba0360d-0fb0-4473-84c9-a7362657431c`,
    `568567fd-2abb-43a4-b030-a6e2d7875387`,
  ]
  if (typeof window === 'undefined') return
  if (window.addEventListener) {
    window.addEventListener('message', handleFormSubmitEvent, false)
  } else if (window.attachEvent) {
    window.attachEvent('onmessage', handleFormSubmitEvent)
  }
  function handleFormSubmitEvent(event) {
    if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
      var submittedData = event.data.data.submissionValues
      var formId = event.data.id
      console.log(formId)
      // Check if the submitted form's ID is in the targetFormIds array
      if (targetFormIds.includes(formId)) {
        var formValues = event.data.data.submissionValues
        //replace routerID with the router ID you've setup
        const RevenueHero = window.RevenueHero
        const hero = new RevenueHero({ routerId: `1226` })
        hero.submit(formValues).then((sessionData) => {
          hero.dialog.open(sessionData)
        })
      }
    }
  }
}

//default id for english site
// let setID = `TDN6QG5`

// const setGtm = () => {
//   insertJS()
// }

// const addgtmLink = `https://www.googletagmanager.com/gtm.js?id=GTM-${setID}`

// //add gtm
// const insertJS = () => {
//   const addIframe = (src) => {
//     const s = document.createElement(`noscript`)
//     const iframe = document.createElement(`iframe`)
//     iframe.src = src
//     iframe.height = '0'
//     iframe.style = 'visiblity:hidden'
//     document.getElementsByTagName(`body`)[0].appendChild(s)
//       .append(`<iframe  src="https://www.googletagmanager.com/ns.html?id=GTM-${setID}"
//         height="0" width="0" style="display:none;visibility:hidden"></iframe>`)
//   }
//   const insertF = () => {
//     const s = document.createElement(`script`)
//     const src = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
//             new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
//             j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
//             'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
//             })(window,document,'script','dataLayer','GTM-${setID}');`
//     document.getElementsByTagName(`head`)[0].appendChild(s).append(src)
//   }

//   addIframe(addgtmLink)
//   insertF()
// }

// const checkCurrCountry = async () => {
//   try {
//     let requestUrl = 'https://geolocation-db.com/json/'
//     let res = await axios.get(requestUrl)
//     currentCountry = await res.data.country_name
//     return currentCountry
//   } catch (error) {
//     console.log(error)
//   }
// }

// const createCustomEventToCheckLocalStorageChanges = () => {
//   var evt = document.createEvent('StorageEvent')
//   evt.initStorageEvent('storage', false, false, 'allowGtm', 'oldValue', 'newValue', null, window.localStorage)
//   window.dispatchEvent(evt)
// }

// const setInitialLocalStorageStates = (...args) => {
//   const [country, allowCookie, showConsentBar] = args
//   let initialObj = JSON.stringify({
//     showConsentBar: showConsentBar,
//     allowCookie: allowCookie,
//     country: country,
//   })
//   localStorage.setItem('onsentConfig', initialObj)
//   sessionStorage.setItem('allowGtm', 'false')
//   console.log('run', initialObj)
// }

exports.wrapRootElement = ({ element }) => {
  return <IsMobileProvider>{element}</IsMobileProvider>
}
